// in src/posts.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  SimpleShowLayout,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  required,
  SelectInput,
  BooleanInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  SimpleForm,
  email,
  regex,
  Toolbar,
    SaveButton,
    DeleteButton,
    TopToolbar,
    BooleanField

} from "react-admin";
import { CompactForm, RaBox } from 'ra-compact-ui';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
  toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
  },
});
const HourValues =  [
  { id: '00:00', name: '00:00 AM' },
  { id: '01:00', name: '01:00 AM' },
  { id: '02:00', name: '02:00 AM' },
  { id: '03:00', name: '03:00 AM' },
  { id: '04:00', name: '04:00 AM' },
  { id: '05:00', name: '05:00 AM' },
  { id: '05:30', name: '05:30 AM' },
  { id: '06:00', name: '06:00 AM' },
  { id: '07:00', name: '07:00 AM' },
  { id: '08:00', name: '08:00 AM' },
  { id: '08:30', name: '08:30 AM' },
  { id: '09:00', name: '09:00 AM' },
  { id: '10:00', name: '10:00 AM' },
  { id: '11:00', name: '11:00 AM' },
  { id: '12:00', name: '12:00 PM' },
  { id: '13:00', name: '01:00 PM' },
  { id: '14:00', name: '02:00 PM' },
  { id: '15:00', name: '03:00 PM' },
  { id: '16:00', name: '04:00 PM' },
  { id: '17:00', name: '05:00 PM' },
  { id: '17:30', name: '05:30 PM' },
  { id: '18:00', name: '06:00 PM' },
  { id: '19:00', name: '07:00 PM' },
  { id: '20:00', name: '08:00 PM' },
  { id: '20:30', name: '08:30 PM' },
  { id: '21:00', name: '09:00 PM' },
  { id: '22:00', name: '10:00 PM' },
  { id: '23:00', name: '11:00 PM' },
];

const filters = [
        <TextInput source = "employee_id" label="ID" alwaysOn/>,
        // <TextInput source = "first_name" label="First Name" />,
        <BooleanInput source = "active" label="Active" alwaysOn/>,
        <SelectInput label="Role" source="role" choices={[
          { id: 'employee', name: 'Employee' },
          { id: 'driver', name: 'Driver' },
          { id: 'admin', name: 'Admin' },
          { id: 'superadmin', name: 'Super Admin' },
        ]} alwaysOn/>,
        <TextInput source = "phone_number" label="Phone Number" />,
        <TextInput source = "email" label="Email" />,
];

const SaveToolbar = (props) => (

  <Toolbar {...props} classes={useStyles()}>
    {(props.record.role==="driver" || props.record.role === "employee") &&
      <SaveButton />
}
  </Toolbar>
);

const UserDeviceEdit = (props) => (
  <Edit {...props} >
    <SimpleForm toolbar={<SaveToolbar />}>
      {(props.record.role==="driver" || props.record.role === "employee") &&
      <TextInput source="device_id" label = "Reset Device? (Clear this field and save to reset)" fullWidth={true}/> }
                  {(props.record.role === "driver") &&
                  <TextInput source="assigned_employee_id" label = "Assigned Employee for Driver" />
}
            {(props.record.role === "employee") &&
      <ArrayInput source="managers" label="Line Managers">
    <SimpleFormIterator>
    <TextInput source="" type="email" validate={[email("Invalid email!")]}/>
    </SimpleFormIterator>
    </ArrayInput>
    }
    </SimpleForm>
  </Edit>
);

export const UserList = ({permissions, ...props}) => (
  <List {...props} filters={filters} bulkActionButtons={false} filterDefaultValues={{ active: true, role: 'admin' }}> 
    <Datagrid expand={<UserDeviceEdit />} rowClick="show">
    <TextField source="employee_id"/>
    <TextField source="first_name"/>
      <TextField source="last_name" />
      <TextField source="email" />
      <TextField source="role" />
      <TextField source="assigned_employee_id" />
    </Datagrid>
  </List>
);


function populateList(numbers) {
  if (numbers == null) return <div></div>;
  return numbers.map((number) =>
      <li key={number.toString()}>
          {number}
      </li>
  );
}

const SimpleArray = ({source, record = {}}) =>
  <ul>
      {
          populateList(record[source])
      }
  </ul>;


SimpleArray.defaultProps = {
  addLabel: true,
  label: 'List'
};

const UserShowActions = ({ permissions, ...props }) => (
  <TopToolbar>
      {permissions.role === "superadmin" &&
      <EditButton basePath={props.basePath} record={props.data} />
      }
  </TopToolbar>
);

export const UserShow = ({ permissions, ...props }) => (
  <Show {...props} actions={<UserShowActions permissions={permissions}/>}>
    <SimpleShowLayout>
      <TextField source="employee_id" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="email" />
      <TextField source="phone_number" />
      <TextField source="role" />
      <TextField source="department"/>
      <TextField source="designation"/>
      <BooleanField source="active" />
      <BooleanField source="is_site_employee" />
      <TextField source="assigned_employee_id" />
      <SimpleArray source="managers" label="Managers"/>
    </SimpleShowLayout>
  </Show>
);

const transform = data => {
  let transformed = {
    ...data
  };
  if (transformed.role == 'admin' || transformed.role == 'superadmin') {
    const result = Math.random().toString(36).substring(2,7);
    console.log(result);
    transformed.employee_id = result;
  }
  if (transformed.employee_id != null) {
    transformed.id = transformed.employee_id;
  }
  transformed.active = true;
  return transformed;
};

const validatePhone = (value) => {
  if (!value) {
      return false;
  }
  return /^(\+91)[0]?(91)?[789]\d{9}$/.test(value);
}

const validateEmail = (value) => {
  if (!value) {
    return false;
  }
  return /^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/.test(value);
}

const checkUniqueEmail = async (value, uid) => {
  let fetch = require('node-fetch');
  try {
  // const response = await fetch('http://localhost:5001/cats-cmrl/us-central1/webApi/api/v1/validate/email', {
    const response = await fetch('https://asia-south1-cats-cmrl.cloudfunctions.net/webApi/api/v1/validate/email', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({email: value, uid: uid}),
  });
  const result = await response.json();
    if (result.success == true) {
      return null;
    } else {
      return result.message;
    }
}
catch(e) {
  console.log(e);
  return "Something went wrong validating email, please try again!";
}

}

const checkUniquePhone = async (value, uid) => {
  let fetch = require('node-fetch');
  try {
  // const response = await fetch('http://localhost:5001/cats-cmrl/us-central1/webApi/api/v1/validate/phone', {
    const response = await fetch('https://asia-south1-cats-cmrl.cloudfunctions.net/webApi/api/v1/validate/phone', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({phone: value, uid: uid}),
  });
  const result = await response.json();
    if (result.success == true) {
      return null;
    } else {
      return result.message;
    }
}
catch(e) {
  console.log(e);
  return "Something went wrong validating phone number, please try again!";
}

}

const validateUserCreation = async (values) => {
  const errors = {};
  const isValidPhone = validatePhone(values.phone_number);
  const isValidEmail = validateEmail(values.email);
  if (!values.phone_number && !values.email) {
    errors.phone_number = "Phone number or Email is required to create account!";
    return errors;
  }
  if (values.phone_number) {
    if (isValidPhone == false) {
      errors.phone_number = "Invalid phone number, it should be of format +919490123456";
    } else {
      const msg = await checkUniquePhone(values.phone_number, values.uid);
      if (msg != null) {
        errors.phone_number = msg;
      }
    }
  }
  if (values.email) {
    if (isValidEmail == false) {
      errors.email = "Invalid email!";
    } else {
      const msg = await checkUniqueEmail(values.email, values.uid);
      if (msg != null) {
        errors.email = msg;
      }
    }
  } 
  return errors
};

export const UserCreate = ({permissions, ...props}) => (
  <Create {...props} transform={transform} redirect="show" >
    <CompactForm layoutComponents={[RaBox]} validate={validateUserCreation}>
      {permissions.role === "admin" &&
    <SelectInput label="Select Role" source="role" choices={[
    { id: 'employee', name: 'Employee' },
    { id: 'driver', name: 'Driver' },
  ]} />
}
{permissions.role === "superadmin" &&
    <SelectInput label="Select Role" source="role" choices={[
    { id: 'employee', name: 'Employee' },
    { id: 'driver', name: 'Driver' },
    { id: 'admin', name: 'Admin' },
    { id: 'superadmin', name: 'Super Admin' },
  ]} />
   }

  <FormDataConsumer>
    {({ formData, ...rest }) => (formData.role === "admin" || formData.role === "superadmin") && <div>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput source="first_name" validate={[required()]}/>
    </RaBox>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput source="last_name" validate={[required()]}/>
    </RaBox>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput label="Email" source="email" type="email"  validate={[required(), email("Invalid email!")]}/>
    </RaBox>
    {/* <RaBox display="flex" justifyContent="space-between">
    <TextInput label="Phone Number" source="phone_number" type="phone" />
    </RaBox> */}
    </div>}
    </FormDataConsumer>
    <FormDataConsumer>
    {({ formData, ...rest }) => (formData.role === "driver") && <div>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput label="Employee ID" source="employee_id" validate={[required()]}/>
    <TextInput source="first_name" validate={[required()]}/>
    <TextInput source="last_name" validate={[required()]}/>
    </RaBox>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput label="Email" source="email" type="email"/>
    </RaBox>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput label="Phone Number" source="phone_number" type="phone"/>
    </RaBox>
    <TextInput source="cab_operators" />
    <BooleanInput source="is_site_employee"/>
    <TextInput source="assigned_employee_id" />
    </div>}
    </FormDataConsumer>
    <FormDataConsumer>
    {({ formData, ...rest }) => (formData.role === "employee") && <div>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput label="Employee ID" source="employee_id" validate={[required()]}/>
    <TextInput source="first_name" validate={[required()]}/>
    <TextInput source="last_name" validate={[required()]}/>
    </RaBox>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput label="Email" source="email" type="email"/>
    </RaBox>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput label="Phone Number" source="phone_number" type="phone"/>
    </RaBox>
  <TextInput source="department"/>
  <TextInput source="designation"/>
    <BooleanInput source="is_site_employee"/>
    <ArrayInput source="managers" label="Line Managers">
    <SimpleFormIterator>
    <TextInput source="" type="email"/>
    </SimpleFormIterator>
    </ArrayInput>
    </div>}
    </FormDataConsumer>
    </CompactForm>
  </Create>
);

const UserTitle = ({ record }) => {
  return <span>{record ? `${record.role.toUpperCase() + ": " + record.id}` : ''}</span>;
};

const CustomToolbar = (basePath, data, redirect, ...props) => (
  <Toolbar {...props} classes={useStyles()}>
      <SaveButton submitOnEnter={false} redirect={redirect} {...props} />
  </Toolbar>
);

const UserEditActions = ({ permissions, ...props }) => (
  <TopToolbar>
      <ShowButton basePath={props.basePath} record={props.data} />
      {permissions.role === "superadmin" &&
      <DeleteButton basePath={props.basePath} record={props.data} />}
  </TopToolbar>
);

export const UserEdit = ({permissions, ...props}) => (
<Edit title={<UserTitle/>} {...props} actions={<UserEditActions permissions={permissions}/>} >
 <CompactForm layoutComponents={[RaBox]}  validate={validateUserCreation}>
  <FormDataConsumer>
    {({ formData, ...rest }) => (formData.role === "admin" || formData.role === "superadmin") && <div>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput source="first_name" validate={[required()]}/>
    </RaBox>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput source="last_name" validate={[required()]}/>
    </RaBox>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput label="Email" source="email" type="email"  validate={[required(), email("Invalid email!")]}/>
    </RaBox>
    <BooleanInput source="active" />
    </div>}
    </FormDataConsumer>
    <FormDataConsumer>
    {({ formData, ...rest }) => (formData.role === "driver") && <div>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput source="first_name" validate={[required()]}/>
    <TextInput source="last_name" validate={[required()]}/>
    </RaBox>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput label="Email" source="email" type="email"/>
    </RaBox>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput label="Phone Number" source="phone_number" type="phone"/>
    </RaBox>
    <TextInput source="device_id"/>
    <TextInput source="cab_operators" />
    <BooleanInput source="is_overnight_shift"/>
    <TextInput source="assigned_employee_id" />
    <BooleanInput source="active" />
    </div>}
    </FormDataConsumer>
    <FormDataConsumer>
    {({ formData, ...rest }) => (formData.role === "employee") && <div>
    <RaBox display="flex" justifyContent="space-between">
    <BooleanInput source="active" />
    </RaBox>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput source="first_name" validate={[required()]}/>
    </RaBox>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput source="last_name" validate={[required()]}/>
    </RaBox>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput label="Email" source="email" type="email"/>
    </RaBox>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput label="Phone Number" source="phone_number" type="phone"/>
    </RaBox>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput source="device_id"/>
    </RaBox>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput source="designation"/>
    </RaBox>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput source="department"/>
    </RaBox>
    <BooleanInput source="is_site_employee"/>
    <ArrayInput source="managers">
    <SimpleFormIterator>
    <TextInput source="" type="email" validate={[email("Invalid email!")]}/>
    </SimpleFormIterator>
    </ArrayInput>
    </div>}
    </FormDataConsumer>
    </CompactForm></Edit>
);
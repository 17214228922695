// FIREBASE_CONFIG.js
export const firebaseConfig = {
    apiKey: "AIzaSyDGUms3rsorG07RBQpZpi70TQRoWg50Fjk",
    authDomain: "cats-cmrl.firebaseapp.com",
    databaseURL: "https://cats-cmrl-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "cats-cmrl",
    storageBucket: "cats-cmrl.appspot.com",
    messagingSenderId: "21977498440",
    appId: "1:21977498440:web:fc8dc6f2c9dee79c19a97d",
    measurementId: "G-FCV4LLHN61"
  };
// in src/Comments.js
import * as React from "react";
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  DateField,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  BooleanInput,
  FunctionField,
  BooleanField,
  TopToolbar, EditButton,
  DateInput,
  SelectInput,
  downloadCSV
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { currentDate, hourFormatter } from "./util/date.util";
import jsonExport from 'jsonexport/dist';

export const locationLink = (v) => {
  if (v==null || v.latitude == null || v.longitude==null) { return v};
  const link = "https://www.google.com/maps/search/?api=1&query=" +v.latitude + ","+v.longitude;
  return <div>
    <a target="_blank" rel="noopener noreferrer" href={link}>View</a>
  </div>
};

export const locationRawLink = (v) => {
  if (v==null || v.latitude == null || v.longitude==null) { return v};
  return "https://www.google.com/maps/search/?api=1&query=" +v.latitude + ","+v.longitude;
};

export const mileage = (i,o) => {
  if (i != null && o != null && parseInt(i) != isNaN && parseInt(o) != isNaN ) {
    return parseInt(o)-parseInt(i);
  }
  return "";
};


const PunchFilters = [
  <DateInput source = "att_date_gte" label="From" alwaysOn />,
  <DateInput source = "att_date_lte" label="To" alwaysOn />,
  <TextInput source = "employee_id" label="ID" alwaysOn/>,
  <SelectInput label="Role" source="role" choices={[
    { id: 'employee', name: 'Employee' },
    { id: 'driver', name: 'Driver' },
  ]} alwaysOn />

];
// actions={<PunchListActions />}

const exporter = punches => {
  const punchesForExport = punches.map(punch => {
    var punchForExport = {};
    punchForExport["ID"] = punch.employee_id;
    punchForExport["Name"] = punch.employee_name;
    punchForExport["Date"] = punch.att_date;
    punchForExport["On Leave"] = punch.is_on_leave;
    punchForExport["In Time"] = hourFormatter(punch.punch_in_date);
    punchForExport["Out Time"] = hourFormatter(punch.punch_out_date);
    punchForExport["Total Hours"] = punch.total_hours;
    punchForExport["Total Shift Hours"] = punch.total_shift_hours;
    punchForExport["In Address"] = punch.punch_in_address;
    punchForExport["Out Address"] = punch.punch_out_address;
    punchForExport["In Location"] = locationRawLink(punch.punch_in_location);
    punchForExport["Out Location"] = locationRawLink(punch.punch_out_location);
    punchForExport["Mileage"] = mileage(punch.punch_in_meter, punch.punch_out_meter);
    punchForExport["Is Flagged"] = punch.is_flagged;
    punchForExport["Flagged Reason"] = punch.flagged_reason;
    punchForExport["Assigned Employee ID"] = punch.assigned_employee_id;
    punchForExport["Trip Start Meter"] = punch.punch_in_meter;
    punchForExport["Trip End Meter"] = punch.punch_out_meter;
    punchForExport["Trip Start Approved"] = punch.punch_in_meter_approved;
    punchForExport["Trip End Approved"] = punch.punch_out_meter_approved;
    punchForExport["Trip Start Approved Employee Address"] = punch.emp_approved_in_address;
    punchForExport["Trip End Approved Employee Address"] = punch.emp_approved_out_address;
    punchForExport["Trip Start Approved Employee Location"] = locationRawLink(punch.emp_approved_in_location);
    punchForExport["Trip End Approved Employee Location"] = locationRawLink(punch.emp_approved_out_location);
    return punchForExport;
  });
  jsonExport(punchesForExport, {
    headers: ['ID', 'Name', 'Date', 'On Leave', "In Time", "Out Time", "Total Hours", "Total Shift Hours", 
    "In Address", "Out Address", "In Location", "Out Location", "Mileage", "Is Flagged", "Flagged Reason", 
    "Assigned Employee ID", "Trip Start Meter", "Trip End Meter", "Trip Start Approved", "Trip End Approved", "Trip Start Approved Employee Address", 
    "Trip End Approved Employee Address", "Trip Start Approved Employee Location", "Trip End Approved Employee Location"] // order fields in the export
  }, (err, csv) => {
      downloadCSV(csv, 'attendance'); // download as 'posts.csv` file
  });
};

export const PunchList = (props) => (
  <List {...props} filters={PunchFilters} bulkActionButtons={false} filterDefaultValues={{att_date_gte: currentDate(), role: 'employee'}} exporter={exporter}>
    <Datagrid expand={<PunchEdit />} rowClick="show">
      <TextField label="ID" source="employee_id"/>
      <TextField label="Name" source="employee_name"/>
      <DateField label="Date" source="att_date"/>
      <BooleanField label="On Leave" source="is_on_leave" sortable={false}/>
      <FunctionField label="In Time" render={record => hourFormatter(record.punch_in_date)} sortable={false}/>
      <TextField label="In Address" source="punch_in_address" sortable={false}/>
      <FunctionField label="Out Time" render={record => hourFormatter(record.punch_out_date)} sortable={false}/>
      <TextField label="Out Address" source="punch_out_address" sortable={false}/>
      <TextField label="Total Hours" source="total_hours" sortable={false}/>
      <TextField label="Total Shift Hours" source="total_shift_hours" sortable={false}/>
      <FunctionField label="Mileage" render={record => mileage(record.punch_in_meter, record.punch_out_meter)} sortable={false}/>
      <BooleanField label="Flagged" source="is_flagged" sortable={false}/>
      <TextField label="Flagged Reason" source="flag_reason" sortable={false}/>
      {/* <EditButton label="" /> */}
      {/* <DeleteButton label="" redirect={false}/> */}
    </Datagrid>
  </List>
);

const PostShowActions = ({ permissions, basePath, data, resource }) => (
  <TopToolbar>
      {permissions.role === 'superadmin' &&
                <EditButton basePath={basePath} record={data} />
      }
  </TopToolbar>
);

export const PunchShow = ({permissions, ...props}) => (
  <Show actions={<PostShowActions permissions={permissions} />} {...props}>
    <SimpleShowLayout>
      <TextField label="Employee ID" source="employee_id" />
      <TextField label="Name" source="employee_name" />
      <DateField label="Date" source="att_date" />
      <TextField source="is_on_leave" />
      <DateField source="punch_in_date" showTime />
      <TextField source="punch_in_address" />
      <DateField source="punch_out_date" showTime />
      <TextField source="punch_out_address" />
      <TextField source="punch_in_meter" />
      <TextField source="total_hours" />
      <TextField source="total_shift_hours" />
      <BooleanField source="is_flagged" />
      <TextField source="flagged_reason" />
      <BooleanField source="punch_in_meter_approved" />
      <TextField source="punch_out_meter" />
      <BooleanField source="punch_out_meter_approved" />
      <FunctionField label="Mileage" render={record => mileage(record.punch_in_meter, record.punch_out_meter)} sortable={false}/>
      <FunctionField label="In Location" render={record => locationLink(record.punch_in_location)} />
      <FunctionField label="Out Location" render={record => locationLink(record.punch_out_location)} />
      <DateField label = "Punch In Date(2)" source="punch_in_date2" showTime />
      <TextField label = "Punch In Address(2)" source="punch_in_address2" />
      <FunctionField label="In Location (2)" render={record => locationLink(record.punch_in_location2)} />
      <DateField label = "Punch Out Date(2)" source="punch_out_date2" showTime />
      <TextField label = "Punch Out Address(2)" source="punch_out_address2" />
      <FunctionField label="Out Location(2)" render={record => locationLink(record.punch_out_location2)} />
      <TextField label= "Punch In Meter Approved Address (Assigned Employee)" source = "emp_approved_in_address" />
      <TextField label= "Punch Out Meter Approved Address (Assigned Employee)" source = "emp_approved_out_address" />
      <FunctionField label="Punch In Meter Approved Location (Assigned Employee)" render={record => locationLink(record.emp_approved_in_location)} />
      <FunctionField label="Punch Out Meter Approved Location (Assigned Employee)" render={record => locationLink(record.emp_approved_out_location)} />
    </SimpleShowLayout>
  </Show>
);

export const PunchEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <BooleanInput source = "is_flagged" label="Flagged" />
      <TextInput source="flagged_reason" label = "Reason for flagging" />
    </SimpleForm>
  </Edit>
);
